/* Login-specific styles to override the .App settings */
.login-root {
  height: 100vh;  /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(51, 51, 51), rgb(96, 108, 56));  /* Login background */
}

.login-app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: white; /* Card background */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  /* Light shadow */
  max-width: 400px;
  width: 100%;
}

.login-card {
  width: 100%;
  text-align: center;
}

.login-title {
  font-size: 32px;
  color: #333;
  margin-bottom: 10px;
}

.login-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.input-group {
  margin-bottom: 20px;
}

.login-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  background-color: rgb(221, 161, 94);;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.login-button:hover {
  background-color: #0056b3;
}