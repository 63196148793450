/* Scoped styles for BusinessDetail */
.business-detail-container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Inter, sans-serif;
}

/* Style for the main business information section */
.business-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.left-container, .right-container, .middle-container {
    flex: 1;
    padding: 10px;
}

.left-container {
    margin-right: 20px;
}

/* Styling for headings and paragraphs */
.business-info h3 {
    color: #333;
    margin-bottom: 10px;
}

.business-info p {
    margin: 5px 0;
}

.business-info strong {
    color: #606c38;
}

/* Styling for related businesses */
.related-businesses-container {
    margin-top: 20px;
}

.related-businesses-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.related-business-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: start;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}
.related-business-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #f9f9f9;
}

.related-business-card h4 {
    color: #333;
    margin-bottom: 10px;
}

.related-business-card p {
    margin: 5px 0;
}

.link {
    color: #606c38;
    text-decoration: none;
}

.link:hover {
    color: #dda15e;
}

.separator-line {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #ccc;
    width: 100%;
}
