/*html, body {*/
/*  height: 100%;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  background-color: #f5f5f5;*/
/*}*/

/*.App:not(.login-root) {*/
/*  text-align: left;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  min-height: 100vh;*/
/*}*/

/*#root {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  height: 100%;*/
/*}*/

/*.App {*/
/*  text-align: left;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  min-height: 100vh;  !* Ensure the app takes up the full height *!*/
/*}*/

main {
  flex: 1;
  padding: 20px;
  background-color: white;
}

/*!* Navbar Specific *!*/
/*.navbar {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  padding: 20px;*/
/*  background-color: #333;*/
/*  color: white;*/
/*  font-family: 'Roboto', sans-serif;*/
/*}*/

/*.navbar-links {*/
/*  list-style: none;*/
/*  display: flex;*/
/*  gap: 20px;*/
/*}*/

/*.navbar-links li {*/
/*  margin: 0;*/
/*}*/

/*.navbar-links a {*/
/*  color: white;*/
/*  text-decoration: none;*/
/*  font-size: 16px;*/
/*}*/

/*.navbar-links a:hover {*/
/*  color: #dda15e;*/
/*}*/