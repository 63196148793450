/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(90deg, #333, #606c38);
  color: white;
}

.navbar-logo a {
  color: white;
  text-decoration: none;
}

.hamburger-menu {
  display: none; // Ensures it's only visible below the breakpoint
  cursor: pointer;
  font-size: 30px;
  user-select: none;
}

/* Responsive adjustments */
@media (max-width: 784px) {
  .navbar-links {
    display: none; // Ensure this is initially hidden
    position: fixed;
    top: 60px;
    right: 0;
    left: 0; // Make sure it stretches across when shown
    background: #333;
    flex-direction: column;
    width: 100%;
    align-items: center;
    z-index: 1001; // Ensure it's above other content
  }

  .navbar-links.show {
    display: flex; // This should show the menu
  }
  .navbar-links.close {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }
}