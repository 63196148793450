/* Reset and Global Layout */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: left;
}

/* Main Content */
main {
  flex: 1;
  padding: 20px;
  background-color: white;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(90deg, #333, #606c38);
  color: white;
  font-family: 'Roboto', sans-serif;

  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;

    a {
      color: white;
      text-decoration: none;
      font-size: 16px;

      &:hover {
        color: #dda15e;
      }
    }
  }
}

/* Responsive Media Queries - Focus on Resizing */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    text-align: center;

    .navbar-links {
      flex-direction: column;
      gap: 10px;
    }
  }

  main {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px;

    .navbar-links a {
      font-size: 14px;
    }
  }

  .signup-container,
  .login-container,
  .centered-container {
    padding: 20px;
    max-width: 90%;
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 700px; /* Maximum width for large screens */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 20px; /* Margin to prevent the modal from touching screen edges on small screens */
  overflow-y: auto; /* Allow scrolling if content overflows */
  max-height: 90vh; /* Limit height to viewport */
}

.modal-content h2 {
  color: #333; /* Ensuring the text is dark for visibility */
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 20px; /* Space below the header */
}

@media (max-width: 600px) {
  .modal-content {
    padding: 20px;
    max-width: 90%;
  }
}

/* Form Styles */
.form-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to next line */
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 0 0 48%;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .form-group {
    flex: 0 0 100%;
  }
}

label {
  color: #333;
  display: block;
  margin-bottom: 5px;
  font-family: 'Inter', sans-serif;
}

input,
select {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.modal-button-group {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.submit-button,
.cancel-button {
  padding: 10px 30px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.submit-button {
  background-color: #283618;
  color: #fff;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}

/* Image Preview */
.image-preview {
  margin-top: 10px;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
  }
}

/* Error Message */
.error-message {
  color: red;
  margin-top: 10px;
}

.redirect-button a {
  color: white;
  text-decoration: none;
}

.search-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-group {
  display: flex;
  align-items: center;
}

.search-group input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 10px;
  flex: 1;
}

.search-group button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #dda15e;
  color: white;
  cursor: pointer;
}

.create-business-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.business-list-container {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Inter, sans-serif;
}

.zip-code-title {
  text-align: center;
  margin-bottom: 20px;
  color: #283618;
 font-family: Inter,sans-serif;
}

/* Grid layout to display businesses in rows of 3 */
.business-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.business-card h4 {
  color: #333;
  margin-bottom: 10px;
}

.business-card p {
  margin: 5px 0;
}

.link {
  color: #606c38;
  text-decoration: none;
}

.link:hover {
  color: #dda15e;
}

.footer {
  background-color: #283618;
  font-family: Inter, sans-serif;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.business-map-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.business-list-column {
  width: 40%;  // Left column width
  padding: 10px;
}

.map-column {
  width: 40%;  // Right column width
  padding: 10px;
  display: flex;
  justify-content: center;
}

.business-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.business-card {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  width: 600px;
  font-family: Inter,sans-serif;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.business-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
}

.pagination-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination-controls button {
  padding: 8px 12px;
  margin: 0 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.business-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  // 3 columns
  grid-gap: 20px;  // Space between cards
  width: 1200px;
}

.near-me-button {
  background-color: #dda15e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.near-me-button:hover {
  background-color:  #606c38
}

.button {
  padding: 8px;
  margin-left: 10px;
  background-color: #ffffff;
  color: #283618;
  border: 2px solid #283618;
  border-radius: 4px;
  cursor: pointer;
  font-family: Inter, sans-serif;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.button:hover {
  background-color: #283618; /* Dark green background on hover */
  color: #ffffff; /* White text color on hover */
}

.search-container {
  display: flex;
  align-items: center;
  height: 300px;
  justify-content: center;
  margin: 20px auto;
  padding: 20px;
  max-width: 1000px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}


