html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  background-color: white;
  padding: 20px;
}

footer {
  padding: 10px;
  text-align: center;
  background: linear-gradient(90deg, #333, #606c38); /* Gradient background */
  color: white;
}